<template>
    <div class="rightLink">
      <div class="bottomDiv">
        <div class="title">充电站添加</div>
        <div class="formDiv">
          <el-form
            ref="FormRules"
            :rules="rules"
            :model="FormData"
            label-position="right"
            label-width="100px"
          >
            <el-row :gutter="20">
              
              <el-col :span="11">
                <el-form-item label="站点名称:">
                  <el-input
                    v-model="FormData.station_name"
                    placeholder="请输入站点名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="选择省份:">
                  <el-select v-model="FormData.province" placeholder="请选择省" @change="handleProvinceChange" style="width: 100%;">
                    <el-option v-for="provinces in provinceList" :key="provinces.value" :value="provinces.label" :label="provinces.label"/>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="选择市:">
                  <el-select v-model="FormData.city" placeholder="请选择市"  @change="handleCityChange" style="width: 100%;">
                    <el-option v-for="citys in cityList[0]" :key="citys.value" :value="citys.label">{{ citys.label }}</el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="选择区/县:">
                  <el-select v-model="FormData.county" placeholder="请选择区/县" style="width: 100%;" >
                    <el-option v-for="countys in countyList[0]" :key="countys.value" :value="countys.label">{{ countys.label }}</el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="社区:">
                  <el-input
                    v-model="FormData.community"
                    placeholder="请输入社区名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="详细地址:">
                  <el-input
                    v-model="FormData.station_addr"
                    placeholder="请输入站点地址"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="经度:">
                  <el-input
                    v-model="FormData.cord_j"
                    placeholder="请输入位置经度"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="纬度:">
                  <el-input
                    v-model="FormData.cord_w"
                    placeholder="请输入位置纬度"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="枪头总数:">
                  <el-input
                    v-model="FormData.plug"
                    placeholder="请输入枪头总数"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="功率:">
                  <el-input
                    v-model="FormData.power"
                    placeholder="请输入功率"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="车型:">
                  <el-select v-model="FormData.type_id" placeholder="请选择充电类型" style="width: 100%;">
                    <el-option v-for="car in carList" :key="car.value" :value="car.value" :label="car.label"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11" v-if="isadmin">
                <el-form-item label="运营商:">
                  <el-select v-model="FormData.username" placeholder="请选择运营商" style="width: 100%;">
                    <el-option v-for="username in users" :key="username.id" :value="username.username" :label="username.username"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11" v-if="!isadmin">
                <el-form-item label="运营商:">
                  <el-input
                    v-model="FormData.username"
                    placeholder="请输入所属运营商"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="btnDiv">
            <!-- <el-button>录入保存</el-button> -->
            <el-button @click="submit">提交</el-button>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { onMounted, reactive, toRefs } from "vue-demi";
  import { add_station,operator_data } from "@/request/api";
  // import ProvinceCityCountry from '../../assets/json/address'
  import { ElMessage } from "element-plus";
  import {  regionData } from 'element-china-area-data'
  
  export default {
    name: "SearchMan",
    setup() {
      const data = reactive({
        //表单数据
        carList:[
            {
              value:2,
              label:'电动车'
            },
            {
              value:4,
              label:'电动汽车'
            }
          ],
          car:'',
        FormData: {
          
        },
  
        // 省市县
        regionData,
        provinceList: [],
        cityList: [],
        countyList: [],
        province: '',
        city: '',
        county: '',
        users:[],
        isadmin:false,
        // provinceData: ProvinceCityCountry.address,
      
      
      });
  
      const initProvinceList =()=> {
        
        // 初始化省份列表数据源    
        regionData.forEach((tab) => {
              data.provinceList.push(tab)
            });
  
      }
      const handleProvinceChange = (val) =>{
        data.cityList=[]
        data.countyList=[]
        data.province = val
        data.FormData.city = ''
        data.FormData.county = ''
         initCityList()
         
      }
  
      
      const initCityList=()=> {
        data.provinceList.forEach((tab1)=>{
          if(tab1.label==data.province){
            data.cityList.push(tab1.children)
          }
        })
    }
  
    const handleCityChange=(value1)=>{
      data.countyList=[]
      data.city = value1
      data.FormData.county = ''
         initCountyList()
      }
  
      const initCountyList=()=> {
        // 初始化县/区列表数据源
        // 初始化城市列表数据源
        data.cityList[0].forEach((tab2)=>{
          if(tab2.label==data.city){
            data.countyList.push(tab2.children)
          }
        })
      }
  
      const submit = () => {
        const dataa = data.FormData
        add_station(dataa).then((res) => {
          if (res) {
            if (res.code == 200) {
              ElMessage({
                showClose: true,
                message: "新增成功",
                type: "success",
              });
              data.FormData = {}
            } else {
              ElMessage.error(res.msg);
            }
          } else {
            ElMessage.error("提交失败");
          }
        });
      };
  
  
      const getUsername = () => {
        const username1 = localStorage.getItem('username')
        const users1 = []
        operator_data().then((res) => {
          data.users = res
          res.forEach((a)=>{    
            users1.push(a.username)
        })
        if(users1.indexOf(username1)==-1){
          data.isadmin = true
        }else{
          data.isadmin = false
          data.FormData.username = username1
          
        }
        });
      };
  
      const handleChange = (value) => {
        console.log(value,'000')
      }
  
     
      onMounted(() => {
        
        initProvinceList()
        getUsername()
        
        
      });
      return {
        ...toRefs(data),
        submit,
        getUsername,
        handleChange,
        initProvinceList,
        handleProvinceChange,
        handleCityChange,
        initCityList,
        initCountyList,
      };
    },
  };
  </script>
  